<template>
  <v-card width="60%" style="margin: 15px auto;">
    <v-card-subtitle class="primary white--text text-center">
      Создание простой карты режимов
    </v-card-subtitle>
    <v-card-text>
      <v-form v-model="dataValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="ID/название/номер карты"
              v-model="card.name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6">
            <div>Согласующая сторона</div>
            <v-text-field
              label="ФИО"
              v-model="card.agreeder.name"
            />
            <v-text-field
              label="Организация"
              v-model="card.agreeder.org"
            />
            <v-text-field
              label="Должность"
              v-model="card.agreeder.post"
            />
            <v-text-field
              label="Дата"
              v-model="card.agreeder.date"
            />
          </v-col>
          <v-col cols="6">
            <div>Утверждающая сторона</div>
            <v-text-field
              label="ФИО"
              v-model="card.accepter.name"
            />
            <v-text-field
              label="Организация"
              v-model="card.accepter.org"
            />
            <v-text-field
              label="Должность"
              v-model="card.accepter.post"
            />
            <v-text-field
              label="Дата"
              v-model="card.accepter.date"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              filled
              rows="4"
              row-height="30"
              label="Краткое описание технологической карты"
              v-model="card.about"
            />
          </v-col>
          <v-col cols="3">
            <v-combobox
              v-model="card.weldMethod"
              :items="weldMethodItems"
              label="Способ сварки"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="3">
            <v-combobox
              v-model="card.elementsType"
              :items="elementsTypeItems"
              label="Вид соединяемых элементов"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="3">
            <v-combobox
              v-model="card.seamType"
              :items="seamTypeItems"
              label="Тип шва"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="3">
            <v-combobox
              v-model="card.jointType"
              :items="jointTypeItems"
              label="Тип соединения"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <div class="text-center">
          <b>Характеристика свариваемых элементов</b>
        </div>
        <v-row>
          <v-col cols="6">
            <div class="text-center">
              Характеристика элемента №1
            </div>
            <v-text-field
              label="Марка материала элемента №1"
              v-model="card.elem1Material"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              Характеристика элемента №2
            </div>
            <v-text-field
              label="Марка материала элемента №2"
              v-model="card.elem2Material"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
          <v-row>
          <v-col cols="6" v-if="card.elementsType !== 'Л+Л' && card.elementsType.length > 0">
            <v-text-field
              v-model="card.d1"
              label="Диаметр элемента 1, мм (D1)"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" v-if="card.elementsType !== 'Л+Л' && card.elementsType !== 'Т+Л' && card.elementsType !== 'С+Л' && card.elementsType.length > 0">
              <v-text-field
                v-model="card.d2"
                label="Диаметр элемента 2, мм (D2)"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="6" v-if="card.elementsType !== 'С+С' && card.elementsType.length > 0">
            <v-text-field
              v-model="card.s1"
              label="Толщина элемента 1, мм (S1)"
              :rules="[rules.required]"
            />
          </v-col>

            <v-col cols="6" v-if="card.elementsType !== 'С+С' && card.elementsType !== 'С+Л' && card.elementsType !== 'С+Т' && card.elementsType.length > 0">
              <v-text-field
                v-model="card.s2"
                label="Толщина элемента 2, мм (S2)"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="card.weldMaterial"
              label="Введите марку сварочного материала"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.weldPosition"
              :items="weldPositionItems"
              label="Положение в пространстве"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Прочие параметры"
              v-model="card.others"
            />
          </v-col>
          <v-col cols="12">
            <Files
              :value="card.files"
              @input="addFile"
              @removeFile="removeFile"
            />
          </v-col>
          <v-col cols="12">
            <Layers v-model="card.layers"></Layers>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="create" :loading="loading" :disabled="loading||!dataValid">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import Files from './components/Files.vue'
import Layers from './components/Layers.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Files,
    Layers
  },
  data: () => ({
    rules: rules,
    dataValid: false,
    loading: false,
    elementsType: null,
    card: {
      orgId: '',
      name: '' ,
      accepter: { name: '', org: '', post: '', date: '' },
      agreeder: { name: '', org: '', post: '', date: '' },
      about: '',
      weldMethod: '',
      elementsType: '',
      d1: '',
      d2: '',
      s1: '',
      s2: '',
      seamType: '',
      jointType: '',
      elem1Material: '',
      elem2Material: '',
      weldMaterial: '',
      weldPosition: '',
      others: '',
      files: [],
      layers: [],
    },
    weldMethodItems: [
      { text: 'РД(111)', tooltip: 'Ручная дуговая сварка покрытыми электродами'},
      { text: 'РАД(141)', tooltip: 'Ручная адгонодуговая сварка неплавящимся электродом'},
      { text: 'МП(135)', tooltip: 'Механизированная сварка плавящимся элетродом в среде активных газов и смесях'},
      { text: 'МАДП(131)', tooltip: 'Механизированная аргонодуговая сварка плавящимся элетродом'},
      { text: 'МПГ(136)', tooltip: 'Механизированная сварка порошковой проволокой в среде активных газов и смесях'},
      { text: 'МПИ(137)', tooltip: 'Механизированная сварка порошковой проволокой в среде инертных газов и смесях'},
      { text: 'МПС(114)', tooltip: 'Механизированная сварка самозащитной порошковой проволокой'},
      { text: 'АФ(12)', tooltip: 'Автоматическая сварка под флюсом'},
      { text: 'ААД(141)', tooltip: 'Автоматическая аргонодуговая сварка неплавящимся элетродом'},
      { text: 'ААДП(131)', tooltip: 'Автоматическая аргонодуговая сварка плавящимся элетродом'},
      { text: 'АПГ(135)', tooltip: 'Автоматическая сварка плавящимся элетродом в среде активных газов и смесях'},
      { text: 'АППГ(136)', tooltip: 'Автоматическая сварка порошковой проволокой в среде активных газов и смесях'},
      { text: 'АПИ(137)', tooltip: 'Автоматическая сварка порошковой проволокой в среде инертных газов и смесях'},
      { text: 'АПС(114)', tooltip: 'Автоматическая сварка самозащитной порошковой проволокой'},
    ],
    elementsTypeItems: [
      { text: 'Т+Т', tooltip: 'Соединение трубы с трубой'},
      { text: 'Л+Л', tooltip: 'Соединение листа с листом (листовым элементом считается любой элемент не имеющий радиуса кривизны в зоне сварного соединения'},
      { text: 'Т+Л', tooltip: 'Соединение трубы с листовым элементом'},
      { text: 'С+С', tooltip: 'Соединение стержня со стрержнем (арматуры с арматурой)'},
      { text: 'С+Л', tooltip: 'Соединение стержня с листовым элементом'},
      { text: 'С+Т', tooltip: 'Соединение стержня с трубой'}
    ],
    jointTypeItems: [
      { text: 'С', tooltip: 'Стыковое соединение' },
      { text: 'У', tooltip: 'Угловое соединение' },
      { text: 'Т', tooltip: 'Тавровое соединение' },
      { text: 'Н', tooltip: 'Нахлесточное соединение' },
    ],
    seamTypeItems: [
      { text: 'СШ', tooltip: 'Стыковой шов' },
      { text: 'УШ', tooltip: 'Угловой шов' },
      { text: 'ТШ', tooltip: 'Точечный шов' },
    ],
    weldPositionItems: [
      { text: 'Н1', tooltip: 'Нижнее стыковое и в "лодочку", а также стыковые и нахлесточные соединения стержней и тавровые соединения стержней с листами, выполняемые в нижнем положении со стороны привариваемого стержня' },
      { text: 'Н2', tooltip: 'Нижнее тавровое, а также крестообразные соединения стержней и нахлесточные соединения стержней с листами, выполняемые в нижнем положении' },
      { text: 'Г', tooltip: 'Горизонтальное' },
      { text: 'П1', tooltip: 'Потолочное стыковое, а также нахлесточные соединения стержней, выполняемые в потолочном положении' },
      { text: 'П2', tooltip: 'Потолочное тавровое, а также крестообразные соединения стержней и нахлесточные соединения стержней с листами, выполняемые в потолочном положении' },
      { text: 'В1', tooltip: 'Вертикальное снизу вверх' },
      { text: 'В2',tooltip: 'Вертикальное сверху вниз' },
      { text: 'Н45',tooltip: 'Наклоненное под углом 45' },
    ],
  }),
    computed: {
      ...mapState('organization', {
        orgId: state => state.profile.id,
      })
    },
    methods: {
      removeFile(e) {
        this.axios.post('file/delete', { url: e.url }).then(() => {
          let index = this.card.files.findIndex(f => f.name == e.name)
          this.card.files.splice(index, 1)
        })
      },
      addFile(file) {
        this.card.files.push(file)
      },
      create() {
        this.loading = true
        this.$axios.post('/simple-card/card/create', { ...this.card, orgId: this.orgId }).then(res => {
          this.$router.push('/simple-card/card/index')
        }).catch(err => {
          if(err.response.data.error) {
            this.serverError = err.response.data.error
          } else {
            this.serverError = 'Не удалось соединиться с сервером'
          }
        }).finally(() => {
          this.loading = false
        })
      }
    }
}
</script>
